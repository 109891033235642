app.contactsMap = {
	start: false
};
app.contactsMap.init = function (options) {
	var settings = $.extend({
			idMapBox: 'contactsMap',
			placemarks: [{
				coords: [47.222531, 39.718705],
				balloon: {
					header: 'г. Ростов-на-Дону',
					body: '',
					footer: ''
				},
				icon: {
					path: ''
				}
			}]
		}, options),
		contactsMap = new ymaps.Map(settings.idMapBox, {
			center: settings.placemarks[0].coords,
			zoom: 16,
			controls: ['smallMapDefaultSet']
		});

	var allPlacemarks = new ymaps.GeoObjectCollection(null);

	$.each(settings.placemarks, function (i, plcmrk) {
		var iconObj = {
			iconLayout: 'default#image',
			iconImageHref: plcmrk.icon.path,
			iconImageSize: plcmrk.icon.size,
			iconImageOffset: plcmrk.icon.offset
		};

		if (plcmrk.icon.path === '') iconObj = {iconLayout: 'default#image'};
		var placemark = new ymaps.Placemark(plcmrk.coords, {
			balloonContentHeader: plcmrk.balloon.header,
			balloonContentBody: plcmrk.balloon.body,
			balloonContentFooter: plcmrk.balloon.footer
		}, iconObj);
		allPlacemarks.add(placemark);
	});

	contactsMap.geoObjects.add(allPlacemarks);
	contactsMap.behaviors.disable(['scrollZoom']);
	// Выставляем масштаб карты чтобы были видны все группы.
	if (settings.placemarks.length > 1) {
		contactsMap.setBounds(contactsMap.geoObjects.getBounds());
		// contactsMap.setZoom(contactsMap.getZoom() - 1);
	}
	$('#'+settings.idMapBox).addClass('loaded');
};