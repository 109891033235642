var vueApp;

// https://github.com/monterail/vuelidate
Vue.use(window.vuelidate.default);

vueApp = new Vue({
	el: '#app-vue',
	components: {
		gallery: galleryComponent,
		guestMenu: guestMenuComponent
	},
	data: {
			gallery: '',
			guest: '',
			action: 'getDataGalleryMain'
	},
	created: function () {
		this.getDataGuest();

		if(CommonHelpers.isDev('vitaminhotel-static.denode.ru')) {
			if (window.location.pathname != '/main.html') {
				var actionData = $('.content').data('action');

				if (actionData) {
					this.action = actionData;
					this.getDataGallery();
				}
			}
		}
		else {
			// - делаем запрос данных для всех страниц, кроме Главной
			if (window.location.pathname != '/') {
				var actionData = $('.content').data('action');

				if (actionData) {
					this.action = actionData;
					this.getDataGallery();
				}
			}
		}
	},
	methods: {
		getDataGallery: function() {
			var url = '/local/ajax.php';

			if(CommonHelpers.isDev('vitaminhotel-static.denode.ru'))
				url = '/json/dataGallery.json';

			if(!this.gallery) {
				this.$http
					.get(url, {
						params: {
							action: this.action
						}
					})
					.then(function(response) {

						if (response.body.data) {

							this.gallery = response.body.data;

						} else {
							console.log('data undefined'); // если данные не получены
						}
					},
					function(response) { // Error.
						console.log('An error occurred.');
					}
				);
			}
		},

		getDataGuest: function() {
			var url = '/local/ajax.php';

			if(CommonHelpers.isDev('vitaminhotel-static.denode.ru'))
				url = '/json/dataGuestMenu.json';

			if(!this.guest) {
				this.$http
					.get(url, {
						params: {
							action: 'getDataGuestMenu'
						}
					})
					.then(function(response) {

						if (response.body.data) {
							this.guest = response.body.data;

						} else {
							console.log('data undefined'); // если данные не получены
						}
					},
					function(response) { // Error.
						console.log('An error occurred.');
					}
				);
			}
		}
	}
});
