(function(sizes, viewport) {

	// {fn} get window sizes
	var getSizes = function() {
		return {
			width: app.dom.$window.width(),
			height: parseInt(window.innerHeight, 10)
		}
	};

	// {fn} get window viewport (for media-queries)
	var getViewport = function() {
		var element = window,
			prefix = 'inner';

		if (!(prefix + 'Width' in window)) {
			prefix = 'client';
			element = document.documentElement || document.body;
		}
		return {
			width: element[prefix + 'Width'],
			height: element[prefix + 'Height']
		};
	};

	// {fn} update sizes
	var update = function() {
		app.sizes = getSizes();
		app.viewport = getViewport();
	};

	// {event} window resize
	app.dom.$window.on('resize.app', update);

	// get current values
	update();

})(app.sizes, app.viewport);