(function($) {
	$(function() {
		$('[data-fancybox]').fancybox({
			protect: true,
			animationEffect: 'fade',
			buttons: [
				'close'
			],
			baseClass: 'simple-popup'
		});

		$('.js-popup').on('click', function(e){
			e.preventDefault();
			var popup = $(this).data('href');
			app.utils.responseForm(popup);
		});

		$('.js-scrollUp').on('click', function(){
			$('html,body').animate({
				scrollTop: 0
			}, 700);
		});

		$('.guestMenuButton').on('click', function(){
			if ($('.page').hasClass('menuOpen')) {
				app.utils.menuClose();
			} else {
				app.utils.menuOpen();
			};
		});

		//- волны
		wave();

		function wave() {
			var $decorBlocks = $('.box__decor').not('.noWave'),
				href='/local/templates/vitaminhotel/static/img/general/ripple.png';

			if (CommonHelpers.isDev('vitaminhotel-static.denode.ru'))
				href = '/static/img/general/ripple.png';

			$.each($decorBlocks, function(){
				var $decorBlock = $(this),
					index = $decorBlock.data('index'),
					$object = $decorBlock.find('.icon');

				if ($decorBlock.find('.svg-filters').length == 0) {
					$decorBlock.append('<svg xmlns="http://www.w3.org/2000/svg" version="1.1" class="svg-filters"><defs><filter id="filter-ripple-'+index+'"><feImage xlink:href="'+href+'" x="30" y="20" width="0" height="0" result="ripple"></feImage><feDisplacementMap xChannelSelector="R" yChannelSelector="G" color-interpolation-filters="sRGB" in="SourceGraphic" in2="ripple" scale="20" result="dm" /><feComposite operator="in" in2="ripple"></feComposite><feComposite in2="SourceGraphic"></feComposite></filter></defs></svg>');
				}

				$object.on('mousemove', function(e) {
					var turb = $decorBlock.find('#filter-ripple-'+index+' feImage'),
						dm = $decorBlock.find('#filter-ripple-'+index+' feDisplacementMap'),
						disabledFunc = function() {
							$decorBlock.removeClass('disabled');
						};

					$decorBlock.addClass('disabled');

					TweenLite.set(turb, { attr: { x: isFF ? e.offsetX : e.offsetX, y: isFF ? e.offsetY : e.offsetY, width: 0, height: 0 }});

					if (app.dom.$window.width() > 1600) {
						TweenLite.to(turb, 3, { attr: { x: '-=500', y: '-=500', width: 1000, height: 1000 }, onComplete: disabledFunc });
						TweenLite.fromTo(dm, 3, { attr: { scale: 100 } }, { attr: { scale: 0 } });
					} else if (app.dom.$window.width() > 1200) {
						TweenLite.to(turb, 3, { attr: { x: '-=300', y: '-=300', width: 600, height: 600 }, onComplete: disabledFunc });
						TweenLite.fromTo(dm, 3, { attr: { scale: 50 } }, { attr: { scale: 0 } });
					}
				});
			});
		}
	});
})(jQuery);