app.loader = {};
(function(loader){
	// {fn} load images
	loader.images = function($block, dataLoad, callback, complete) {
		var loaded = 0;
		// check callbacks
		complete = complete || $.noop;
		callback = callback || $.noop;
		// init plugin
		$block.imagesLoaded({
			background: dataLoad
		}, function(){
		}).progress(function(instance){
			loaded++;
			console.log(instance.images);
			callback(loaded, instance.images.length);
		}).done(function(){
			complete();
			if (loaded == 0) {
				callback(1, 1);
			}
		});
	};
})(app.loader);