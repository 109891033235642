var galleryComponent;

galleryComponent = Vue.component('gallery-vue', {
	template: '#gallery-vue',
	props: ['gallery'],
	components: {
	},
	data: function() {
		return {}
	},
	updated: function () {
		this.scriptInit();
	},
	methods: {

		scriptInit: function() {
			if ($('.gallerySlider').hasClass('gallerySlider_pagination')) {
				var deg = 0;

				var galleryTop = new Swiper('.gallerySlider .gallery-top', {
					spaceBetween: 10,
					effect: 'fade',
					preloadImages: false,
					lazy: true,
					preloaderClass: 'spinner',
					navigation: {
						nextEl: '.gallerySlider .swiper-button-next',
						prevEl: '.gallerySlider .swiper-button-prev',
					},
					pagination: {
						el: '.gallerySlider .swiper-pagination',
						type: 'progressbar',
					},
					keyboard: {
						enabled: true,
						onlyInViewport: false,
					},
					on: {
						slidePrevTransitionStart: function() {
							$('.compass').css({
								'transform': 'rotate('+(deg-90)+'deg)'
							});
							deg = deg - 90;
						},
						slideNextTransitionStart: function() {
							$('.compass').css({
								'transform': 'rotate('+(deg+90)+'deg)'
							});
							deg = deg + 90;
						}
					}
				});
			} else {
				var galleryTop = new Swiper('.gallerySlider .gallery-top', {
					spaceBetween: 10,
					effect: 'fade',
					navigation: {
						nextEl: '.gallerySlider .swiper-button-next',
						prevEl: '.gallerySlider .swiper-button-prev',
					},
					preloadImages: false,
					lazy: true,
					preloaderClass: 'spinner',
					keyboard: {
						enabled: true,
						onlyInViewport: false,
					}
				});
			}

			if ($('.gallerySlider').hasClass('gallerySlider_pagination')) {
				var galleryThumbs = new Swiper('.gallerySlider .gallery-thumbs', {
					spaceBetween: 0,
					simulateTouch: true,
					slidesPerView: 4,
					slideToClickedSlide: false
				});
			} else {
				var galleryThumbs = new Swiper('.gallerySlider .gallery-thumbs', {
					spaceBetween: 17,
					simulateTouch: true,
					slidesPerView: 6,
					slideToClickedSlide: false,
					breakpoints: {
						1280: {
							slidesPerView: 6
						},
						2000: {
							slidesPerView: 9
						}
					}
				});
			}

			var $galleryThumbs = $('.gallery-thumbs'),
				$galleryTop = $('.gallery-top'),
				$slides = $galleryThumbs.find('.swiper-slide'),
				$slidesTop = $galleryTop.find('.swiper-slide'),
				$curSlide = $slides.eq(0).addClass('active'),
				$bigSlide = $galleryTop.find('.swiper-slide-active');

			$slides.on('click', function(event) {
				var index = $(this).index();
				galleryTop.slideTo(index, 500, true);

				$slides.removeClass('active');
				$(this).addClass('active');
			});

			galleryTop.on('slideChangeTransitionEnd', function() {
				var $bigSlide = $galleryTop.find('.swiper-slide-active'),
					index = $bigSlide.index();

				$slides.removeClass('active');
				$slides.eq(index).addClass('active');
				galleryThumbs.slideTo(index-3, 500, true);
			});
		}
	}
});

