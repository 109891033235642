(function($) {
	$(function() {
		var isMobile = function() {
			if (app.sizes.width < 1025) {
			} else {
				app.dom.$window.on('load', function() {
					$('.box_mainBlock').each(function() {
						var self = this,
							$box = $(self);

						var scaleSlider = new Swiper($box.find('.scaleSlider .swiper-container'), {
							effect: 'fade',
							autoplay: {
								delay: 4000,
							},
							speed: 1000,
							simulateTouch: false,
							on: {
								slideChangeTransitionStart: function () {
									$(this.slides).eq(this.activeIndex).addClass('scaleUp');
								},
								slideChangeTransitionEnd: function () {
									$(this.slides).eq(this.previousIndex).removeClass('scaleUp');
								}
							}
						});
					});
				});
			}
		};
		isMobile();
		app.dom.$window.resize(function() {
			isMobile();
		});
	});
})(jQuery);