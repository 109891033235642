$.fn.sTabs = function(scroll) {
	this.each(function() {
		var $wrapTab = $(this),
			$tabs = $wrapTab.find('.tabsList li'),
			$wrapCont = $wrapTab.find('.tabsBox'),
			$tab_cont = $wrapCont.find('div');

		$tabs.on('click', function() {
			var tab_id = $(this).attr('class').split(' ');

			$tabs.removeClass('active');
			$(this).addClass('active');
			$tab_cont.removeClass('active');
			$wrapCont.find('.tabs__con_' + tab_id[0]).addClass('active');
			app.dom.$window.resize();
			return false;
		});
	});
};