(function($){
	$(function(){
		$('.service').each(function(){
			var $block = $(this),
				$items = $block.find('.service__item');

			$items.each(function(){
				var $item = $(this),
					$header = $item.find('.service__header'),
					$content = $item.find('.service__content');

				var open = function(){
					$item.addClass('open');
					$content.animate({ opacity:1 }, { duration:400, queue:false });
					$content.slideDown(450);
				};

				var close = function(){
					$item.removeClass('open');
					$content.animate({ opacity:0 }, { duration:400, queue:false });
					$content.slideUp(450);
				};

				$item.data('part', { close:close, open:open });

				if ($content.length > 0) {
					$header.click(function(){
						var $opened = $items.filter('.open');
						if ($opened.is($item)) {
							close();
						} else {
							if ($opened.length) $opened.data('part').close();
							open();
						}
					});
				} else {
					$item.addClass('empty');
				}
			});
		});
	});
})(jQuery);