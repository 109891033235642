$.fn.slideNav = function(options) {
	var settings = $.extend(true, {}, options),
		$object = this;

	var $page = app.dom.$page,
		$slides = $object.find('.box'),
		$navs = $object.find('.js-navItem'),
		initHash = location.hash.replace('#', ''),
		oldBgPreSet = '',
		$arrowUp = $('.js-slideUp');

	window.onhashchange = function() {
		var hash = window.location.hash.replace('#', ''),
				$items = $navs.filter('[data-href="' + hash + '"]');

		setSlide(null, $items);
	};

	$navs.on('click', function() {
		var href = $(this).data('href'),
			$items = $navs.filter('[data-href="'+href+'"]');

		setSlide(href, $items);

		// window.history.pushState(null, null, '/main.html#' + href);
		window.history.pushState(null, null, '/#' + href);
	});

	$arrowUp.on('click', function() {
		var targetClass = $(this).data('href');
		setSlide(targetClass, $navs.filter(`[data-href="${targetClass}"]`));
	});

	if (initHash === '') {
		var $initNav = $navs.eq(0);

		setSlide($initNav.data('href'), $initNav);
	}
	else {
		setSlide(initHash);
	}

	function setSlide(targetClass, $navItem) {
		var $navItem = $navItem || $navs.filter(`[data-href="${targetClass}"]`),
			targetClass = targetClass || $navItem.data('href'),
			$targetSlide = $slides.filter(`[data-id="${targetClass}"]`),
			curBgPreSet = $targetSlide.data('bg-preset');

		if (!$('.page').hasClass('menuOpen')) {

			if(targetClass === 'gallery') // делаем запрос данных при переходе на слайд с галлерей
				vueApp.getDataGallery();

			$object.data('bg-preset', curBgPreSet);
			$object.add($page).removeClass(oldBgPreSet).addClass(curBgPreSet);

			$navs.removeClass('active');
			$navItem.addClass('active');

			$slides.removeClass('box_show');
			$targetSlide.addClass('box_show');

			if ($object.hasClass('object_active')) {
				location.hash = targetClass;
			}
			oldBgPreSet = curBgPreSet;
			$object.data('hash', targetClass);
			disableScroll();
		}
	};

	function nextSlide() {
		var $curNav = $navs.filter('.active'),
			indexCurNav = $curNav.index();

		if (!$('.page').hasClass('menuOpen')) {
			if (indexCurNav < ($navs.length - 1)) {
				setSlide('', $navs.eq(indexCurNav + 1));

				var href = $navs.eq(indexCurNav + 1).data('href');

				// window.history.pushState(null, null, '/main.html#' + href);
				window.history.pushState(null, null, '/#' + href);
			}
		}
	};

	function prevSlide() {
		var $curNav = $navs.filter('.active'),
			indexCurNav = $curNav.index();

		if (!$('.page').hasClass('menuOpen')) {
			if (indexCurNav > 0) {
				setSlide('', $navs.eq(indexCurNav - 1));

				var href = $navs.eq(indexCurNav - 1).data('href');

				// window.history.pushState(null, null, '/main.html#' + href);
				window.history.pushState(null, null, '/#' + href);
			}
		}
	};

	$(document).keyup(function(e) {
		if (e.which == 38) {
			prevSlide();
		} else if (e.which == 40) {
			nextSlide();
		}
	});

	function enableScroll() {
		$object.on('mousewheel', function(event) {
			if (event.deltaY < 0) {
				nextSlide();
			} else {
				prevSlide();
			}
		});
	};

	function disableScroll(temp = true) {
		$object.off('mousewheel');
		if (temp) {
			setTimeout(function() {
				enableScroll();
			}, 2000);
		}
	};
	$object.data('slideNav', { enableScroll, disableScroll });
};

(function($) {
	$(function() {
		var isMobile = function() {
			if (app.sizes.width < 943) {
				app.dom.$page.addClass('page_mobile');
				app.utils.menuClose();
			} else {
				app.dom.$page.removeClass('page_mobile');
				app.dom.$content.slideNav();
			}
		};
		isMobile();
	});
})(jQuery);

