(function ($, dom) {
	$(function () {
		var countSlides = $('.locationBlockSlider').data('count') || 3;
		var swiper = new Swiper('.locationBlockSlider .swiper-container', {
			slidesPerView: countSlides,
			slidesPerGroup: 1,
			spaceBetween: 10,
			loop: false,
			simulateTouch: true,
			autoplay: {
				delay: 4000
			},
			navigation: {
				nextEl: '.locationBlockSlider .swiper-button-next',
				prevEl: '.locationBlockSlider .swiper-button-prev',
			},
			pagination: {
				el: '.locationBlockSlider  .swiper-pagination',
				type: 'bullets',
				clickable: true
			},
			breakpoints: {
				768: {
					slidesPerView: 1
				}
			},
			on: {
				setTransition: function() {
					var $pagination = $('.serviceBlock .swiper-pagination'),
						$bullets = $pagination.find('.swiper-pagination-bullet');

					if ($bullets.length > 1) $bullets.addClass('visible');
				}
			}
		});
	});
})(jQuery, app.dom);