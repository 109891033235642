function cleanPath(path) {
	if (path[0] == '/')
		return path.substring(1);
	else
		return path
}
function pluralForm ( n, forms ) {
	return forms[(n%10==1 && n%100!=11 ? 0 : n%10>=2 && n%10<=4 && (n%100<10 || n%100>=20) ? 1 : 2) ];
}
function getCookie(name) {
	var matches = document.cookie.match(new RegExp(
		"(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
	));
	return matches ? decodeURIComponent(matches[1]) : undefined;
}
function setCookie(name, value, options) {
	options = options || {};

	var expires = options.expires;

	if (typeof expires == "number" && expires) {
		var d = new Date();
		d.setTime(d.getTime() + expires * 1000);
		expires = options.expires = d;
	}
	if (expires && expires.toUTCString) {
		options.expires = expires.toUTCString();
	}

	value = encodeURIComponent(value);

	var updatedCookie = name + "=" + value;

	for (var propName in options) {
		updatedCookie += "; " + propName;
		var propValue = options[propName];
		if (propValue !== true) {
			updatedCookie += "=" + propValue;
		}
	}

	document.cookie = updatedCookie;
}
function deleteCookie(name) {
	setCookie(name, "", {
		expires: -1
	})
}
(function(dom, utils, config){

	utils.delayRender = function(callback){
		return setTimeout(callback, config.renderDelay);
	};

	utils.delayDuration = function(callback){
		return setTimeout(callback, config.duration);
	};

	utils.prevent = function(e){
		e.preventDefault();
	};

	utils.pageScroll = {};
	utils.pageScroll.disable = function(){
		dom.$document.on('touchmove', function(event){
			event.preventDefault();
		});
		dom.$window.on('mousewheel', function(event){
			event.preventDefault();
		});
	};
	utils.pageScroll.enable = function(){
		dom.$document.off('touchmove');
		dom.$window.off('mousewheel');
	};

	utils.footerToBottom = function ($target) {
		var $targetBox = $target || dom.$footer.prev('.box');

		$targetBox.css('min-height', '');

		var heightContent = dom.$footer.offset().top + dom.$footer.outerHeight(true),
			dif = app.sizes.height - heightContent;

		if (dif > 0) $targetBox.css('min-height', $targetBox.outerHeight() + (app.sizes.height - heightContent));
	};
	// used
	utils.responseForm = function(thanksPopup) {
		$.fancybox.close(true);
		$.fancybox.open($(thanksPopup), {
			baseClass : 'thanks-popup'
		});
	};

	utils.menuOpen = function() {
		$('.page').addClass('menuOpen');
		$('.guestMenu').slideDown(600, 'easeOutQuint');
	};

	utils.menuClose = function() {
		$('.page').removeClass('menuOpen');
		$('.guestMenu').slideUp(600, 'easeOutQuint');
	};

	utils.tabs = function($tabBox, navItems, content, scroll) {
		$tabBox.each(function() {
			var $box = $(this),
				id = 0;

			if (scroll) $(content).mCustomScrollbar({ autoHideScrollbar: true });

			$.each($(navItems), function(){
				if ($(this).hasClass('active')) id = $(this).data('index');
			});

			if (id == 0) $(navItems).eq(id).addClass('active');
			$(content).filter(content + '_' + id).addClass('active');

			$(navItems).on('click', function() {
				var id = $(this).data('index');

				$(navItems).removeClass('active');
				$(this).addClass('active');
				$(content).removeClass('active');
				$(content).filter(content + '_' + id).addClass('active');
			});
		});
	};
})(app.dom, app.utils, app.config);
