var guestMenuComponent;

var guestMenuLocationComponent = Vue.component('guest-menu-location-vue', {
	template: '#guest-menu-location-vue',
	props: ['tabLocation', 'values']
});

var guestMenuDefaultComponent = Vue.component('guest-menu-default-vue', {
	template: '#guest-menu-default-vue',
	props: ['tabDefault']
});

guestMenuComponent = Vue.component('guest-menu-vue', {
	template: '#guest-menu-vue',
	props: ['guest'],
	components: {
		guestMenuLocationComponent: guestMenuLocationComponent,
		guestMenuDefaultComponent: guestMenuDefaultComponent
	},
	data: function() {
		return {
			values: '',
			viewType: 'location',
			tabDefault: '',
			tabLocation: ''
		}
	},
	updated: function () {
		this.getData();
		this.scriptInit();

		if (this.tabLocation.length === 0) {
			this.tabLocation = this.values[0].dataTab;
			$('.guestMenuNav__item').filter('[data-view="'+this.viewType+'"]').addClass('active');
		}
	},
	methods: {
		getData: function() {
			if (!this.values)
				this.values = vueApp.guest.tabs;
		},

		changeView: function(type, id) {
			this.viewType = type;

			var filteredData = '';

			filteredData = this.values.filter(function(el) {
				return el.id == id;
			});

			this.tabDefault = filteredData[0].dataTab;
		},

		scriptInit: function() {
			var $guestMenu = $('.guestMenu'),
				$tabBox = $guestMenu.find('.guestMenu__right'),
				$scrollBox = $tabBox.find('.js-scrollBox'),
				$navItems = $guestMenu.find('.guestMenuNav__item');


			$navItems.on('click', function() {
				$navItems.removeClass('active');
				$(this).addClass('active');
			});

			$scrollBox.mCustomScrollbar('destroy');
			$scrollBox.mCustomScrollbar({
				autoHideScrollbar: true
			});

			$(document).on('beforeShow.fb', function( e, instance, slide ) {
				if (!$('.fancybox-container').hasClass('thanks-popup')) {
					$('.fancybox-container').addClass('simple-popup');
					$('.fancybox-toolbar').find('button').not('[data-fancybox-close]').addClass('hide');
				}
			});
		}
	}
});
