/* --- App --- */
var app = {
	sizes: {},
	viewport: {},
	device: {},
	utils: {}
};

/* --- Config --- */
app.config = {
	duration: 350,
	renderDelay: 1000 / 60,
	scrollReady: true
};

/* --- DOM --- */
app.dom = {
	$root: $('#root'),
	$html: $('html'),
	$body: $('body'),
	$mainWrappers: $('html, body'),
	$document: $(document),
	$window: $(window)
};

$(function () {
	app.dom.$page = $('.page');
	app.dom.$content = $('.content');
	app.dom.$header = $('.header');
	app.dom.$footer = $('.footer');
});