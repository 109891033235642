(function ($) {
	$(function () {
		var $header = $('.header'),
			blockH = $('.topBlock').height(),
			headerH = $header.height(),
			scrollPosition = function() {
				var pos = $(this).scrollTop();
				if (pos > (blockH-headerH)) {
					if (!$header.hasClass('dark')) $header.addClass('dark');
				} else $header.removeClass('dark');
			};

		scrollPosition();

		app.dom.$document.on('scroll', function(){
			scrollPosition();
		});

		app.dom.$window.resize(function() {
			blockH = $('.topBlock').height(),
			headerH = $header.height();
			scrollPosition();
		});
	});
})(jQuery);