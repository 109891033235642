(function(device, dom, sizes) {

	/* --- Mobile --- */
	device.support = Modernizr;

	/* --- Mobile --- */
	device.isMobile = device.support.touch;
	dom.$html.addClass(device.isMobile ? 'd-mobile' : 'd-no-mobile');

	/* --- Phone --- */
	var phoneCheck = function() {
		device.isPhone = (app.sizes.width < 800);
		dom.$html.addClass(device.isPhone ? 'd-phone' : 'd-no-phone');
		dom.$html.removeClass(device.isPhone ? 'd-no-phone' : 'd-phone');
	};
	phoneCheck();
	dom.$window.on('resize.phone-check', phoneCheck);

	/* --- Retina --- */
	device.isRetina = (window.devicePixelRatio && window.devicePixelRatio > 1);

	/* --- iOS --- */
	if (navigator.userAgent.match(/iPad/i)) {
		dom.$html.addClass('d-ipad');
		device.isIPad = true;
	};
	if (navigator.userAgent.match(/(iPhone|iPod touch)/i)) {
		dom.$html.addClass('d-iphone');
		device.isIPhone = true;
	};
	if (navigator.userAgent.match(/(iPad|iPhone|iPod touch)/i)) {
		dom.$html.addClass('d-ios');
		device.isIOS = true;
	};
	if (navigator.userAgent.match(/.*CPU.*OS 7_\d/i)) {
		dom.$html.addClass('d-ios7');
		device.isIOS7 = true;
	};

	/* --- iPad (for fix wrong window height) --- */
	if (dom.$html.hasClass('d-ipad d-ios7')) {
		dom.$window.on('resize orientationchange focusout', function() {
			window.scrollTo(0, 0);
		});
	};

	device.BrowserDetect = {
		init: function() {
			this.browser = this.searchString(this.dataBrowser) || "Other";
			this.version = this.searchVersion(navigator.userAgent) || this.searchVersion(navigator.appVersion) || "Unknown";
		},
		searchString: function(data) {
			for (var i = 0; i < data.length; i++) {
				var dataString = data[i].string;
				this.versionSearchString = data[i].subString;

				if (dataString.indexOf(data[i].subString) !== -1) {
					return data[i].identity;
				}
			}
		},
		searchVersion: function(dataString) {
			var index = dataString.indexOf(this.versionSearchString);
			if (index === -1) {
				return;
			}
			var rv = dataString.indexOf("rv:");
			if (this.versionSearchString === "Trident" && rv !== -1) {
				return parseFloat(dataString.substring(rv + 3));
			} else {
				return parseFloat(dataString.substring(index + this.versionSearchString.length + 1));
			}
		},
		dataBrowser: [{
			string: navigator.userAgent,
			subString: "Edge",
			identity: "MS Edge"
		}, {
			string: navigator.userAgent,
			subString: "Chrome",
			identity: "Chrome"
		}, {
			string: navigator.userAgent,
			subString: "MSIE",
			identity: "Explorer"
		}, {
			string: navigator.userAgent,
			subString: "Trident",
			identity: "Explorer"
		}, {
			string: navigator.userAgent,
			subString: "Firefox",
			identity: "Firefox"
		}, {
			string: navigator.userAgent,
			subString: "Safari",
			identity: "Safari"
		}, {
			string: navigator.userAgent,
			subString: "Opera",
			identity: "Opera"
		}]
	};
	device.BrowserDetect.init();
	// app.device.BrowserDetect.browser
	// app.device.BrowserDetect.version
})(app.device, app.dom, app.sizes);