(function ($) {
	$(function () {
		if (typeof initConfig != 'undefined') {
			$.each(initConfig.map, function(index, el) {
				var idMap = el.id,
					$mapBox = $('#'+idMap),
					start = false,
					yamapCheckAndInit = function () {
						if (!start && $mapBox.length) {
							ymaps.ready(function () {
								app.contactsMap.init({
									idMapBox: idMap,
									placemarks: el.placemarks
								});
							});
							start = true;
						}
					};
				yamapCheckAndInit();
			});
		}
	});
})(jQuery);