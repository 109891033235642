(function ($) {
	$(function () {
		var $box = $('.aboutPageDocs'),
			$tabBox = $box.find('.aboutPageDocs__right'),
			$linkMore = $tabBox.find('.js-docsMore'),
			$contents = $tabBox.find('.aboutPageDocsContent'),
			href = '/local/ajax.php';

		app.utils.tabs($tabBox, '.aboutPageNav__item', '.aboutPageDocsContent', false);

		if(CommonHelpers.isDev('vitaminhotel-static.denode.ru'))
			href = 'json/docs.json';

		$linkMore.on('click', function(e) {
			e.preventDefault();

			var self = $(this),
				id = $(this).data('id'),
				$container = $('.aboutPageDocsContent_' + id).find('.aboutPageDocsList');

			if (!self.hasClass('loading')) {
				self.addClass('loading');

				$.ajax({
					url: href,
					contentType: false,
					type: 'GET',
					dataType: 'json',
					data: {
						action: 'getDocsItems',
						sectionId: id,
						params: self.data('params'),
						limit: $('.aboutPageDocsContent_' + id + ' .js_productItem').length,
						cache: 'N'
					},
					success: function(json) {
						if ((json.status == 200) || (json.status == 'lastRecords')) {
							var $response = $(json.html);

							$container.append($response);

							self.removeClass('loading');

							$('[data-fancybox]').fancybox({
								protect: true,
								animationEffect: 'fade',
								buttons: [
									'close'
								],
								baseClass: 'simple-popup'
							});

							if (json.status == 'lastRecords') self.addClass('hide');
						} else console.log('status error');
					}
				});
			}
		});
	});
})(jQuery);