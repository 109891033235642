(function ($, dom) {
	$(function () {
		var $preloader = $('.preloader'),
			$progress = $preloader.find('.progress'),
			$counter = $preloader.find('.counter'),
			$logo = $preloader.find('.preloader__logo svg'),
			$activeBox = app.dom.$content,

			closePreloader = function() {
				$preloader.fadeOut(1000, 'easeInOutCubic', function () {
					dom.$page.addClass('page_ready');
					dom.$page.removeClass('page_loading');
				});
			},
			callBackLoad = function(loaded, allLoading) {
				$progress.stop().animate({
					width: (loaded / allLoading) * 100 + '%'
				}, 500, 'easeInOutCubic');

				var value = Math.floor((loaded / allLoading) * 100);
				$counter.find('.num').text(value);

				if ($logo) {
					$logo.find('.water-fill').attr('y', '10%');
					$logo.find('.water-fill').attr('y', ((loaded / allLoading)*100) + '%');
				}
			},
			loadPage = function(page, $activeBox) {
				if (page == 'main') { // загрузка изображений для главной
					var $loadBg = $activeBox.data('load');

					app.loader.images($activeBox, '[data-load]', callBackLoad, function(){
						if (typeof $loadBg != 'undefined') { //- если есть bg у главного блока
							app.loader.images($activeBox, true, callBackLoad, function(){
								app.loader.images($('.guestMenu'), '[data-load]', callBackLoad, closePreloader);
							});
						} else { //- если нет bg у главного блока
							app.loader.images($('.guestMenu'), '[data-load]', callBackLoad, closePreloader);
						}
					});

				} else {
					app.loader.images($activeBox, '[data-load]', callBackLoad, function(){
						app.loader.images($('.guestMenu'), '[data-load]', callBackLoad, closePreloader);
					});
				}
			};

		if ($preloader.length) {
			app.dom.$page.addClass('page_loading');

			// определение акивного экрана для главной - start
			if(CommonHelpers.isDev('vitaminhotel-static.denode.ru')) { // статика
				if (window.location.pathname == '/main.html') {
					var hash = window.location.hash.replace('#', ''),
						$activeBox = $('.box_mainBlock');

					if (hash != '') {
						$activeBox = $('.box_'+hash+'Block');
					}
					loadPage('main', $activeBox);
				} else {
					loadPage('inner', $activeBox);
				}
			}
			else { // prod
				if (window.location.pathname == '/') {
					var hash = window.location.hash.replace('#', ''),
						$activeBox = $('.box_mainBlock');

					if (hash != '') {
						$activeBox = $('.box_'+hash+'Block');
					}
					loadPage('main', $activeBox);
				} else {
					loadPage('inner', $activeBox);
				}
			}
			// end
		}
	});
})(jQuery, app.dom);