var YaHelpers = (function() {
  'use strict';

  var _myMap,
    _myCollection,
    _initConfig;

  /**
   * Инициализация карты
   * @param id {string}
   * @param initMapConfig {Object}
   * @returns {Promise}
   */
  function initMap(id, initMapConfig) {
    var deferred = $.Deferred();

    if(!$('#' + id).length) {
      // Карты нет
      deferred.reject();
    }
    else {
      _initConfig = initMapConfig || {};

      ymaps.ready(function() {
        _myMap = new ymaps.Map(id, initMapConfig.map);

        // Карта готова, резолвим
        deferred.resolve();
      });
    }
    $('#' + id).addClass('loaded');

    return deferred.promise();
  }

  /**
   * Перемещение по карте
   * @param el {jQuery}
   */
  function goToPlace(el) {
    $(el).on('click', function() {
      var $parentBlock = $(this).parents('[data-placemark]'),
        singleCoord = getSingleCoord($parentBlock);

      _myMap
        .panTo(coords, {
          // Задержка между перемещениями.
          delay: 100,
          duration: 1000
        })
        .then(function() {
          _myMap.setZoom(14, {
            smooth: 1,
            duration: 500
          }).then(function() {
            setMyCenter(coords, 14);
          });
        });
    });
  }

  /**
   * Получить одиночную координату
   * @param el {jQuery}
   * @returns {Array}
   */
  function getSingleCoord(el) {
    return $(el).data('placemark').split(',').map(function(el) {
      return +el;
    });
  }

  /**
   * Добавить несколько placemarks на карту
   * @param allCoords {Array[]}
   */
  function addAllPlacemarks(allCoords) {
    // Создаем коллекцию геообъектов.
    _myCollection = new ymaps.GeoObjectCollection();

    allCoords.forEach(function(el) {
      var placemark = new ymaps.Placemark(el.coord, el.balloon, _initConfig.placemark);

      _myCollection.add(placemark);
    });

    // Добавляем коллекцию на карту.
    _myMap.geoObjects.add(_myCollection);
    // Устанавливаем карте центр и масштаб так, чтобы охватить коллекцию целиком.
    _myMap.setBounds(_myCollection.getBounds(), {
      checkZoomRange: true
    });

    // todo если в ините укзана одна координата - потестить
    if(allCoords.length > 1) {
      // Устанавливаем карте центр и масштаб так, чтобы охватить коллекцию целиком.
      _myMap.setBounds(_myCollection.getBounds(), {
        duration: 500,
        checkZoomRange: true,
        zoomMargin: [0, 400, 0, 0] // Если задан массив из 4х чисел, то это отступы top, right, bottom, left
      });
    }
    else {
      setMyCenter(allCoords[0].coord, 14);
    }
  }

  /**
   * Установить центр карты
   * @param coord {Array}
   * @param zoom {number}
   */
  function setMyCenter(coord, zoom) {
    _myMap.setCenter(coord, zoom, {
      checkZoomRange: true,
      duration: 500
    }).then(function() {
      setCenterOffset(0, 0, zoom);
    })
  }

  /**
   * Сдвиг центра карты в пикселях по x или y
   * @param x {number}
   * @param [y] {number}
   * @param [zoom] {number}
   */
  function setCenterOffset(x, y, zoom) {
    x = x || 0,
      y = y || 0,
      zoom = zoom || 10;

    var position = _myMap.getGlobalPixelCenter();

    _myMap.setGlobalPixelCenter([position[0] + x, position[1] + y], zoom, {
      duration: 1000,
      checkZoomRange: true
    });
  }

  return {
    initMap:        initMap,
    goToPlace:      goToPlace,
    getSingleCoord: getSingleCoord,
    addAllPlacemarks: addAllPlacemarks,
    setCenterOffset: setCenterOffset,
    setMyCenter: setMyCenter
  }
})();