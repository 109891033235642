var CommonHelpers = (function() {
	'use strict';

	// todo перенести в js-tools
	/**
	 * Проверка загрузки изображений
	 * @param objImages {object}
	 * @returns {promise}
	 */
	function checkImgLoading(objImages) {
		var deferred = $.Deferred();

		var loaded = false;

		$.each(objImages, function(i, el) {
			$('<img />').attr('src', el.src).on('load', function() {
				$(this).remove();

				objImages[i].loaded = true;
			});
		});

		var int = setInterval(function() {
			$.each(objImages, function(i, el) {
				loaded = el.loaded ? true : false;
			});

			if(loaded) {
				clearInterval(int);

				// Все изображения загрузились, резолвим
				deferred.resolve();
			}
		}, 250);

		return deferred.promise();
	}

  /**
	 * Вальдемар, поменяй этаж и отрисуй заново карту, будь другом, а?
   * @param items {object}
   * @param arr {array}
   */
  function valdemar(items, arr) {
    var $map = $('.box_blockMap').find('.map');

    $.each(items.buildings, function(i, el) {
      changeFloor(arr.floor);

      if(el.id == arr.id) {
        app.dom.$page.addClass('focus');

        $map.find('svg').remove();

        magicMap(items, el.id);
      }
    });
  }

  /**
	 * Поиск по элементам карты
   * @param items {object}
   * @param arr {array}
   */
	function searchMap(items, arr) {
    var dataKey = 'dataMap-' + arr.floor;

    items = items || JSON.parse(sessionStorage.getItem(dataKey));

    // Если не были переданы данные или данные не были получены локально, то делаем запрос на сервер
		if (!items) {
			requestDataMap(arr.floor)
				.then(function(items) {
          valdemar(items, arr);
        });
    }
    else {
      valdemar(items, arr);
    }
	}

  /**
	 * Запрос данных с сервера для карты
   * @param floor {object}
   * @returns {promise}
   */
	function requestDataMap(floor) {
    var deferred = $.Deferred(),
				url= '',
				dataKey = 'dataMap-' + floor;

		if(CommonHelpers.isDev('gold-babylon-static.denode.ru'))
      url = 'static/components-assets/blockMap/' + dataKey + '.json';

    $.ajax({
      type: 'GET',
      dataType: 'json',
      data: {
        action: 'getMap-' + floor
      },
      url
    })
      .done(function(items) {
        var data = items.data[0];

        sessionStorage.setItem(dataKey, JSON.stringify(data));

        // резолвим промис с полученными данными
        deferred.resolve(data);
      })
      .fail(function() {
        console.log('ajax error');
      })
      .always(function() {
        //$spinner.hide();
      });

    return deferred.promise();
  }

	/**
	 * Получить данные для карты
	 * @param id {string|number}
	 */
	function getDataMap(id) {
		id = id || 0;

		var dataKey = 'dataMap-' + id,
			storeMap = JSON.parse(sessionStorage.getItem(dataKey));

		// Если данные получены локально
		if(storeMap) {
			magicMap(storeMap);
		}
		else {
			requestDataMap(id)
				.then(function(data) {
          magicMap(data);
        });
		}
	}

	/**
	 * Проверка локального окружения на основе адресной строки
	 * @param staticUrl {string}
	 * @return {boolean}
	 */
	function isDev(staticUrl) {
		var hostName = window.location.hostname;

		return (hostName === 'localhost') || (hostName === staticUrl);
	}

	/**
	 * Преобразовать число с запятой в число с точкой
	 * @param num {Number}
	 * @returns {Number}
	 */
	function commaToDot(num) {
		num = num || 0;

		return parseFloat(num.replace(',', '.'));
	}

	/**
	 * Вешать на keydown, передавать контекст
	 * @param e
	 */
	function onlyNumbers(e) {
		var self = $(this);

		if((e.which < 48 || e.which > 57))
			e.preventDefault();

		self.val(self.val().replace(/[^\d].+/, ""));
	}

	/**
	 * Задержка исполнения функции
	 * @see https://github.com/component/debounce
	 * @param func {function}
	 * @param wait {number}
	 * @param [immediate] {bool}
	 * @returns {debounced}
	 */
	function debounce(func, wait, immediate) {
		var timeout, args, context, timestamp, result;
		if(null == wait) wait = 100;

		function later() {
			var last = Date.now() - timestamp;

			if(last < wait && last >= 0) {
				timeout = setTimeout(later, wait - last);
			}
			else {
				timeout = null;
				if(!immediate) {
					result  = func.apply(context, args);
					context = args = null;
				}
			}
		};

		var debounced = function() {
			context     = this;
			args        = arguments;
			timestamp   = Date.now();
			var callNow = immediate && !timeout;
			if(!timeout) timeout = setTimeout(later, wait);
			if(callNow) {
				result  = func.apply(context, args);
				context = args = null;
			}

			return result;
		};

		debounced.clear = function() {
			if(timeout) {
				clearTimeout(timeout);
				timeout = null;
			}
		};

		debounced.flush = function() {
			if(timeout) {
				result  = func.apply(context, args);
				context = args = null;

				clearTimeout(timeout);
				timeout = null;
			}
		};

		return debounced;
	}


	return {
		isDev: isDev,
		commaToDot: commaToDot,
		debounce: debounce,
		getDataMap: getDataMap,
		checkImgLoading: checkImgLoading,
		searchMap: searchMap
	}
})();